import mitt from 'mitt'
import type { DefineComponent } from 'vue'
import type { AlertCallback, EAlertType } from '@/types/ui'

export enum ENotification {
  showBusyState = 'showBusyState',
  hideBusyState = 'hideBusyState',
  showAlert = 'showAlert',
  showBanner = 'showBanner',
  hideBanner = 'hideBanner'
}

export enum EOverlayType {
  loading,
  message
}

export enum ENotificationType {
  info,
  warning,
  error
}

export enum EOverlayTheme {
  light,
  dark
}

export interface ShowBusyStateNotificationObject {
  delay?: number
  message?: string
  type?: EOverlayType
  icon?: DefineComponent
  theme?: EOverlayTheme
}

export interface ShowBannerNotificationObject {
  delay?: number
  message?: string
  type?: ENotificationType
}

export type ShowAlertNotificationButtonSpec = string[]

export interface ShowAlertNotificationObject {
  type?: EAlertType
  title: string
  description: string
  onDidDismiss: AlertCallback
  buttons?: ShowAlertNotificationButtonSpec
}

interface NotificationObject {
  [key: string | symbol]: unknown
  [ENotification.showBusyState]?: ShowBusyStateNotificationObject
  [ENotification.showAlert]: ShowAlertNotificationObject
}
/* eslint-disable */
const notifications = mitt<NotificationObject>()

export const { addObserver, removeObserver, postNotification, clear } = {
  addObserver: notifications.on,
  removeObserver: notifications.off,
  postNotification: notifications.emit,
  clear: notifications.all.clear
}
/* eslint-enable */
