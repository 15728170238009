<template>
  <div class="x-icon h-5 w-5 flex items-center justify-center text-lg font-bold text-white rounded-full bg-red-400">
    &times;
  </div>
</template>

<script lang="ts"></script>

<style lang="scss">
.x-icon {
  min-width: 20px;
}
</style>
