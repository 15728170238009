const translations = {
  $: {
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    error: 'Błąd',
    errors: {
      contact_support:
        'jeżeli ten błąd wystąpi ponownie, skontaktuj się ze wsparciem podając ten kod',
      email: 'Proszę podaj prawidłowy email.',
      generic_header: 'Przepraszamy, ale wystąpił błąd po naszej stronie.',
      unexpected: 'An unexpected error occurred{error}'
    },
    go: 'Zaloguj',
    language: {
      en: 'English',
      pl: 'Polski'
    },
    page_not_found: 'Hmm... Nie mogliśmy odnaleźć strony której szukasz.',
    try_again: 'Spróbuj ponownie',
    your_email: 'Twój email'
  },
  holoports: {
    empty_list: 'Nie posiadasz żadnych zarejestrowanych HoloPort-ów.',
    header: 'Linki do Host Console Twoich Holoport-ów',
    title: 'HoloPort'
  },
  know_your_customer: {
    contact: 'Kontakt',
    jurisdiction: 'Jurysdykcja',
    name: 'Imię i nazwisko',
    title: 'Dane Personalne'
  },
  footer: {
    support_forum: 'Forum Wsparcia'
  },
  login: {
    check_you_email: 'Sprawdź swój email any zalogować się do Springboard',
    email_question: 'Podaj swój email',
    header: 'Podaj swój email aby zalogować się do Holo Springboard',
    link: 'Zarejestruj się w Holo',
    link_prefix: 'Pierwszy raz tutaj?'
  },
  publisher: {
    email: 'Login Email',
    header: 'Link do Publisher Portal',
    new: {
      message:
        'Twoja rejestracja jest procesowana, otrzymasz email jeżeli spełniasz kryteria dołaczenia do Publisher Alpha Program.',
      button_text: 'Zarejestruj się jako Publisher'
    },
    sent: {
      message: 'Zastałeś zaakceptowany aby dołączyć do Publisher Alpha Program',
      button_text: 'Zarejestruj się jako Publisher'
    },
    title: 'hApp Publisher',
    unregistered: {
      message: 'Umieść swoją aplikację w Holo cloud hosting marketplace',
      button_text: 'Zarejestruj się jako Publisher'
    }
  },
  sidebar: {
    description:
      'Samoobsługowy punkt w którym znajdziesz wszystko co potrzebujesz związanego z Holo. Zarządzaj swoimi HoloPort-ami, zaktualizuj dane, otrzymuj kody rejestracyjne i więcej.',
    title: 'Holo Springboard:'
  },
  springboard: {
    description: 'Tutaj znajdziesz wszystko co potrzebujesz dla swoich ról w ekosystemie Holo.',
    header: 'Twój Holo Springboard'
  },
  terms_of_service: {
    checkbox_label: 'Zaznacz to pole, aby zaakceptować',
    link: 'Warunki usługi wersji Alpha ',
    message: 'Zarejestruj się aby zobaczyć czy możesz dołączyć do Publisher Alpha Program.',
    registered_message:
      'Sprawdzimy czy możesz dołączyć do Publisher Alpha Program. Jeżeli tak, otrzymasz email z zaproszenie do programu.',
    registered_title: 'Dziękujemy za rejestrację jako Publisher!',
    title: 'Warunki usługi'
  }
} as const

export default translations
