/* eslint-disable */
import type { AxiosResponse } from 'axios'
import { RegistrationService } from './services'
import { authenticate } from './auth'
import type { RegisterPublisherResponse, RegistrationCode, UserData } from '@/types/api'
import { ERegistrationRole, EStatus } from '@/types/api'
import { getPersistedUser } from '@/utils'

const registrationRoleToIndex = (role: ERegistrationRole) => {
  if (role === ERegistrationRole.host) {
    return 0
  } else if (role === ERegistrationRole.publisher) {
    return 1
  } else if (role === ERegistrationRole.user) {
    return 2
  } else {
    throw new Error(`invalid registration role was received ${role}`)
  }
}

export const mongoDbInterface = {
  getHoloports: async (): Promise<string[]> => {
    const user = getPersistedUser()

    if (user?.email) {
      const token: string = await authenticate()

      const result: AxiosResponse = await RegistrationService.findRegisteredHps(token, user?.email)
      return result.data
    } else {
      return []
    }
  },

  getUserData: async (registrationRole?: ERegistrationRole): Promise<UserData> => {
    const user = getPersistedUser()

    const token: string = await authenticate()

    const result = await RegistrationService.findOne(token, user?.email || '')

    const { legalJurisdiction, givenNames, lastName, email, registrationCode } = result.data

    // Find first registration code for given role if provided,
    // or return first registration code if we are not looking for any particular role.
    const foundRegistrationCode = registrationRole
      ? registrationCode.find(
          (registrationCode: RegistrationCode) => registrationCode.role === registrationRole
        )
      : registrationCode[0]

    // If we were looking for registration code with publisher role and there is none,
    // take the first registration code to get the data and set status to.
    if (registrationRole === ERegistrationRole.publisher && !foundRegistrationCode) {
      return {
        jurisdiction: legalJurisdiction,
        legalName: `${givenNames} ${lastName}`,
        primaryEmail: email,
        status: EStatus.unregistered
      }
    }

    // If we found a role we were looking for extract data from it.
    return {
      jurisdiction: legalJurisdiction,
      legalName: `${givenNames} ${lastName}`, // TODO: Discuss which name approach we're keeping - it's inconsistent across collections (fullName vs given + last)
      primaryEmail: email,
      status: foundRegistrationCode.status || EStatus.unregistered
    }
  },

  registerAsPublisher: async (): Promise<RegisterPublisherResponse> => {
    const user = await getPersistedUser()

    if (user?.email) {
      const token: string = await authenticate()

      const role = ERegistrationRole.publisher
      const roleType = registrationRoleToIndex(role)
      const result = await RegistrationService.addRole(token, {
        email: user?.email,
        roleType
      })

      const { email, registrationCode } = result.data
      const { status } = registrationCode
      console.log(status)

      return {
        email,
        status: EStatus.new // TODO: use real data returned by addRole method?
      }
    } else {
      return {
        email: '',
        status: EStatus.unregistered
      }
    }
  },

  updateEmail: async () => {
    throw new Error('Not Implemented')
  },

  verifyEmail: async (email: string): Promise<boolean> => {
    const token: string = await authenticate()

    const { data } = await RegistrationService.verifyEmail(token, email)
    return data
  },

  resendRegistrationCode: async (email: string, registrationRole: ERegistrationRole) => {
    const token: string = await authenticate()
    const roleType = registrationRoleToIndex(registrationRole)

    const { data } = await RegistrationService.resendRegistrationCode(token, { email, roleType })
    return data
  }
}
/* eslint-enable */
