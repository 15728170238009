/* eslint-disable */
import axios from 'axios'
import { SERVER_URI, CLIENT_ID, CLIENT_SECRET } from '@/utils/consts'

export async function authenticate(): Promise<string> {
  try {
    /* eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment  */
    const result = await axios({
      method: 'get',
      url: `${SERVER_URI}/api/auth`,
      params: {
        id: CLIENT_ID,
        secret: CLIENT_SECRET
      }
    })

    if (result.status === 200) {
      return result.data
    } else {
      throw new Error('auth_error')
    }
  } catch (e: any) {
    return e.message
  }
}
/* eslint-enable */
