import { createWebHistory, createRouter, Router, RouteRecordRaw } from 'vue-router'
import LoginView from '@/pages/LoginView.vue'
import NotFound from '@/pages/NotFound.vue'
import Springboard from '@/pages/SpringboardView.vue'
import { timeoutAsync } from '@/utils'
import { validateUser } from '@/utils/magicLink'

const kUserValidationTimeout = 10000

interface Routes {
  login: RouteRecordRaw
  home: RouteRecordRaw
  error: RouteRecordRaw
  catch: RouteRecordRaw
}

export const kRoutes: Routes = {
  login: {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      guest: true
    }
  },
  home: {
    path: '/home',
    name: 'Springboard',
    component: Springboard,
    meta: {
      private: true
    }
  },
  error: {
    path: '/404',
    name: 'Error',
    component: NotFound
  },
  catch: {
    path: '/:catchAll(.*)', // Unrecognized path automatically matches 404
    name: 'Catch',
    redirect: '/404'
  }
} as const

export function routerFactory(): Router {
  const router = createRouter({
    history: createWebHistory(),
    routes: [kRoutes.login, kRoutes.home, kRoutes.error, kRoutes.catch]
  })

  router.beforeEach((to, _, next) => {
    const { magicCredential } = to.query

    // We need to clear any previously logged in user when redirected from the registration app
    if (magicCredential) {
      localStorage.removeItem('user')
    }

    const isLoggedIn = !!localStorage.getItem('user')

    if (to.matched.some((record) => record.meta.private) && !isLoggedIn) {
      Promise.race([validateUser(magicCredential), timeoutAsync(kUserValidationTimeout)])
        .then(() => {
          // Continue to springboard homepage if user validation succeeds
          next()
        })
        .catch((e) => {
          localStorage.removeItem('user')
          // Redirect to request-link page when user validation fails
          next({
            name: String(kRoutes.login.name),
            params: {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              error: e
            }
          })
        })
    } else if (to.matched.some((record) => record.meta.guest)) {
      // Prevent the request-link page from showing when user is authorized
      if (magicCredential) {
        next({
          name: String(kRoutes.home.name),
          query: to.query
        })
      } else {
        next()
      }
    } else {
      next()
    }
  })

  return router
}

const router = routerFactory()

export default router
