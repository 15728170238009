import axios, { AxiosInstance } from 'axios'
import { SERVER_URI } from '@/utils/consts'

/* eslint-disable @typescript-eslint/naming-convention */
export default (token: string): AxiosInstance =>
  axios.create({
    baseURL: `${SERVER_URI}/api`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `bearer ${token}`
    }
  })
/* eslint-enable */
