/* eslint-disable */
import type { AxiosResponse } from 'axios'
import httpCommon from './httpCommon'

class RegistrationServiceClass {
  async findRegisteredHps(token: string, email: string): Promise<AxiosResponse> {
    return httpCommon(token).get(`/register/find-registered-hps/${email}`)
  }

  async findOne(token: string, email: string): Promise<AxiosResponse> {
    return httpCommon(token).get(`/register/find-one/${email}`)
  }

  async addRole(token: string, data: Record<string, unknown>): Promise<AxiosResponse> {
    return httpCommon(token).post('/register/add-role', data)
  }

  async verifyEmail(token: string, email: string): Promise<AxiosResponse> {
    return await httpCommon(token).get(`/register/verify-email/${email}`)
  }

  async resendRegistrationCode(token: string, data: Record<string, unknown>): Promise<AxiosResponse> {
    return await httpCommon(token).post('/register/resend-registration-record', data)
  }
}

export const RegistrationService = new RegistrationServiceClass()

/* eslint-enable */
