/* eslint-disable */
import {ExtensionError, Magic, MagicUserMetadata, RPCError, RPCErrorCode, SDKError} from 'magic-sdk'
import type {LocationQueryValue} from 'vue-router'
import type {Error} from '@/types/common';
import {ENotification, postNotification} from "@/utils/notifications";
import {updatePersistedUser} from "@/utils/index";

const magic = new Magic(`${process.env.VUE_APP_MAGIC_KEY}`)

export function isMagicLinkError(error: Error | unknown): error is SDKError | ExtensionError | RPCError {
  return error !== undefined
}

export async function callMagicLink(email: string): Promise<MagicUserMetadata | void> {
  try {
    await magicLogout()
    await magic.auth.loginWithMagicLink({ email, showUI: false })

    postNotification(ENotification.showBusyState)

    return await magic.user.getMetadata()
  } catch (error) {
    if (isMagicLinkError(error)) {
      const msg = getMagicErrorMessage(error)
      throw new Error(msg)
    }

    return
  }
}

export async function validateUser(magicToken: LocationQueryValue | LocationQueryValue[] | undefined): Promise<MagicUserMetadata | null | void> {
  try {
    postNotification(ENotification.showBusyState)

    await magicLogout()
    await magic.auth.loginWithCredential(`${magicToken}`)
    const user = await magic.user.getMetadata()

    if (user) {
      await updatePersistedUser(JSON.stringify(user))
    }

    return user
  } catch (error) {
    if (isMagicLinkError(error)) {
      const msg = getMagicErrorMessage(error)
      throw new Error(msg)
    }

    return
  } finally {
    postNotification(ENotification.hideBusyState)
  }
}

export async function magicLogout(): Promise<void> {
  await magic.user.logout()
}

const rpcMessages = {
  [`${RPCErrorCode.MagicLinkExpired}`]:
    'Your magic link has expired. (This can happen if the user takes more than 10 minutes or longer ot check your email after requesting the code.) Please request new magic link.',
  [`${RPCErrorCode.MagicLinkFailedVerification}`]:
    'Your magic link failed validation. This could be due to a an internal service error or a generic network error. Please wait a few minutes and try again.',
  [`${RPCErrorCode.MagicLinkInvalidRedirectURL}`]:
    'Your magic was unable to be processed due to an internal error with redirect url. Please contact support.',
  [`${RPCErrorCode.InternalError}`]:
    'Your magic was unable to be processed due to an internal error. Please contact support.'
}

export function getMagicErrorMessage(err: SDKError | ExtensionError | RPCError): string {
  let msg = ''

  if (err instanceof SDKError || err instanceof ExtensionError) {
    msg = 'There was a internal software error while logging you in. Please contact support.'
  }

  if (err instanceof RPCError && err.code) {
    msg =
      rpcMessages[err.code] ??
      'There was a connection error while attempting validate your link. Please wait a few minutes and try again.'
  }

  return msg
}
/* eslint-enable */
