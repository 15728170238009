import { onMounted, onUnmounted } from 'vue'
import { isTimeout } from '@/types/predicates'

const kInactiveUserWarningTimeThresholdInSeconds = 480
const kUserActivityThrottlerTime = 1000

export function useActivityTracker(openInactiveUserModal: () => void): {
  deactivateActivityTracker: () => void
} {
  let userActivityThrottlerTimeout: NodeJS.Timeout | number | null = null
  let userActivityTimeout: NodeJS.Timeout | number | null = null

  function resetUserActivityTimeout(): void {
    if (isTimeout(userActivityTimeout)) {
      clearTimeout(userActivityTimeout)
    }

    userActivityTimeout = setTimeout(() => {
      openInactiveUserModal()
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    }, kInactiveUserWarningTimeThresholdInSeconds * 1000)
  }

  function userActivityThrottler(): void {
    if (!userActivityThrottlerTimeout) {
      userActivityThrottlerTimeout = setTimeout(() => {
        resetUserActivityTimeout()

        if (isTimeout(userActivityThrottlerTimeout)) {
          clearTimeout(userActivityThrottlerTimeout)
          userActivityThrottlerTimeout = null
        }
      }, kUserActivityThrottlerTime)
    }
  }

  function activateActivityTracker(): void {
    window.addEventListener('mousemove', userActivityThrottler)
    window.addEventListener('scroll', userActivityThrottler)
    window.addEventListener('keydown', userActivityThrottler)
    window.addEventListener('resize', userActivityThrottler)
  }

  function deactivateActivityTracker(): void {
    window.removeEventListener('mousemove', userActivityThrottler)
    window.removeEventListener('scroll', userActivityThrottler)
    window.removeEventListener('keydown', userActivityThrottler)
    window.removeEventListener('resize', userActivityThrottler)
  }

  onMounted(() => {
    activateActivityTracker()
    resetUserActivityTimeout()
  })

  onUnmounted(() => {
    deactivateActivityTracker()

    if (isTimeout(userActivityTimeout)) {
      clearTimeout(userActivityTimeout)
    }

    if (isTimeout(userActivityThrottlerTimeout)) {
      clearTimeout(userActivityThrottlerTimeout)
    }
  })

  return { deactivateActivityTracker }
}
