const translations = {
  $: {
    cancel: 'Cancel',
    confirm: 'Confirm',
    continue: 'Continue',
    close: 'Close',
    error: 'Error',
    errors: {
      email: 'Please enter a valid email.',
      email_does_not_exist:
        'The email you provided does not exist in our database. Please register first.',
      generic_header: 'Oops, looks like we can’t fetch this data right now.',
      invalid_email: 'You have entered an invalid e-mail address. Please try again.',
      try_refreshing: 'Try refreshing the page.',
      unexpected: 'An unexpected error occurred{error}'
    },
    go: 'Go',
    language: {
      en: 'English',
      pl: 'Polski'
    },
    logging_out: 'Logging out...',
    logout: 'Logout',
    page_not_found: "Hmm... We're unable to find that page.",
    try_again: 'Try again',
    your_email: 'Your email'
  },
  footer: {
    support_forum: 'Support Forum'
  },
  holoports: {
    empty_list: 'You have no registered HoloPorts.',
    header: 'Links to your HoloPort(s) Host Console',
    title: 'HoloPort'
  },
  inactive_user: {
    header: 'No activity',
    inactive_user_no_activity: 'There has been no activity for 8 minutes.',
    logged_out_user_no_activity: 'There has been no activity for 10 minutes.',
    logged_out: 'For security reasons you have been logged out.',
    log_in_to_continue: 'To continue using Springboard, please log in again.',
    you_will_be_logged_out: 'You will automatically be logged out in:',
    would_you_like_to_continue: 'Would you like to continue this session?'
  },
  know_your_customer: {
    contact: 'Primary Contact',
    jurisdiction: 'Legal Jurisdiction',
    name: 'Legal Name',
    title: 'KYC & Personal Info'
  },
  login: {
    check_you_email: 'Check your email to access Springboard',
    email_question: 'What is your email address?',
    header: 'Enter your email to access your Holo springboard',
    link: 'registering with Holo',
    link_prefix: 'First time? Start by'
  },
  publisher: {
    email: 'Login Email',
    header: 'Link to the hosted Publisher Portal',
    new: {
      message:
        'Your registration is under review and you’ll receive an email if you meet the criteria to join the Publisher Alpha Program.',
      button_text: 'Register as a Publisher'
    },
    sent: {
      message: "You've been accepted into the Publisher Alpha Program.",
      button_text: 'Resend registration code'
    },
    title: 'hApp Publisher',
    unregistered: {
      message: 'Have your app hosted on Holo’s distributed cloud hosting marketplace.',
      button_text: 'Register as a Publisher'
    }
  },
  sidebar: {
    description:
      'A self-service jumping off point for all your Holo needs. Including managing your HoloPorts, updating KYC, receiving registration codes, and more.',
    title: 'Holo Springboard:'
  },
  springboard: {
    description:
      'This is where you can find all the needed jumping off points for your various Holo ecosystem roles, as well as access additional Holo applications.',
    header: 'Your Holo Springboard'
  },
  terms_of_service: {
    checkbox_label: 'Check this box to accept the',
    link: 'Alpha Terms of Service',
    message: 'Submit your registration to see if you’re eligible for the Publisher Alpha Program.',
    registered_message:
      'We will review your eligibility for the Publisher Alpha Program and if you meet the criteria, you’ll receive an email to join the program.',
    registered_title: 'Thanks for registering as a Publisher!',
    title: 'Terms of Service'
  }
} as const

export default translations
